<template>
  <div>
    <PageHeader :items="items" />
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col lg="3">
            <div class="faq-card">
              <div class="faq-card-header" style="background: pink;">
    
              </div>
              <div class="faq-card-body">
                <h3>Guide</h3>
                <p>Browser over in-depth guides<br> to help you get started.</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="faq-card">
              <div class="faq-card-header" style="background: yellow;">
                
              </div>
              <div class="faq-card-body">
                <h3>Webinars</h3>
                <p>Watch recorded webinars<br> to level up your knowledge.</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="faq-card">
              <div class="faq-card-header" style="background: orange;">
                
              </div>
              <div class="faq-card-body">
                <h3>Account</h3>
                <p>Learn more about how to manage <br>your DentalTwin account.</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="faq-card">
              <div class="faq-card-header" style="background: greenyellow;">
                
              </div>
              <div class="faq-card-body">
                <h3>Billing</h3>
                <p>Find answers to frequently<br> asked billing questions.</p>
              </div>
            </div>
          </b-col>
        </b-row>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BButton,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
} from "bootstrap-vue";
import FaqQuestionAnswer from "./FaqQuestionAnswer.vue";
import PageHeader from "@/components/PageHeader.vue";
export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BButton,
    BImg,
    FaqQuestionAnswer,
    PageHeader
  },
  computed: {
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("FAQ"),
          active: true,
        },
      ];
    },
    faqData() {
      return {
        // product and services
        productServices: {
          icon: "SettingsIcon",
          title: this.$t("Product & Services"),
          subtitle: "",
          qandA: [
            {
              question: this.$t("How much can I actually save?"),
              ans: this.$t("Our calculations revealed time savings and material savings of more than 50%, resulting in more than 50% cost savings."),
            },
            {
              question: this.$t("I am not happy with the results, what should I do?"),
              ans: this.$t("We are working on a ticket system. For now please use help@dentaltwin.de to explain why you are not happy with the generated results."),
            },
            {
              question:
                this.$t("Is this product really using AI or is it just a marketing-claim?"),
              ans: this.$t("Yes we are using true AI technologies in our products. Our systems are based on Deep Learning and advanced algorithms to deliver the best and most reliable quality possible."),
            },

            {
              question: this.$t("How can I access your services?"),
              ans: this.$t("Our software is browser-based."),
            },
            {
              question:
                this.$t("For which indicatons can I use the reconstructed 3D models?"),
              ans: this.$t("The model has a wide range of potential uses, e.g. dental CADCAM design, implantology, smile design, and TMJ therapy."),
            },
            {
              question: this.$t("In which  software can I import the models?"),
              ans: this.$t("You can import it into any CADCAM-Software, which support STL-Files as input (e.g. Exocad)"),
            },

            {
              question: this.$t("Can DentalTwin also be used without a pano xray?"),
              ans: this.$t("Yes, even without a pano xray from the pastDentalTwin can provide a template using AI."),
            },
            {
              question: this.$t("In which format must the xray be available?"),
              ans: this.$t("The pano xray can be sent digitally as dicom or jpeg-file. Physical images on film can be sent directly to our scanning service."),
            },
            {
              question: this.$t("How exactly does the workflow look like?"),
              ans: this.$t("After creating a patient case in our DentalTwinHub, the IO scan and pano xray are sent to the portal. After a few minutes the reconstructed 3D model is available for both, dental lab and clinic for the import to any CADCAM- software.  Using the Bio-Copy-function the prosthesis can be designed directly or with modifications based on the file of the original teeth."),
            },
          ],
        },
        // payment
        payment: {
          icon: "CreditCardIcon",
          title: this.$t("Payment & Licensing"),
          subtitle: "",
          qandA: [
            {
              question: this.$t("Does my product automatically renew?"),
              ans: this.$t("No, you always have to buy new cases when you need them. There are no hidden subscriptions!"),
            },
            {
              question: this.$t("Can I have a free case to test the software?"),
              ans: this.$t("Yes every user gets on default 3 cases to test and check the software! If you need any further cases, please contact sales@dentaltwin.de and also mention the reason."),
            },
            {
              question: this.$t("Can I use the models for my website or flyer?"),
              ans: this.$t("Yes the models are created by our AIs and are not copyrighted in anyway. So you may use them for anything you want."),
            },
            {
              question: this.$t("I want to have a bigger package, what should I do?"),
              ans: this.$t("Please directly contact sales@dentaltwin.de"),
            },
            {
              question: this.$t("Are there any differences in the provided packages?"),
              ans: this.$t("The provided packages only differ in amount of cases and not in quality or anything else. So it does not matter if you buy 10x one case or directly the 10 cases package."),
            },
            {
              question: this.$t("Can I also pay the cases after I used them?"),
              ans: this.$t("No this is not possible at the moment."),
            },
          ],
        },
      };
    },
  },
  data() {
    return {
      faqSearchQuery: "",
    };
  },

  methods: {
    fetchData() {
      console.log(this.faqSearchQuery);
      //this.$http.get('/faq/data', { params: { q: this.faqSearchQuery } }).then(res => { this.faqData = res.data })
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
